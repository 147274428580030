@import './nodeImage.scss';
@import './header.scss';
@import './sidebar.scss';
@import './loggedInUsers.scss';
@import './userBox.scss';
@import './mobileMenu.scss';
@import './grid.scss';
@import './scrollbars.scss';

.layout {
  height: 100vh;
  background: #FAFAFC;

  .brand,
  header,
  .highlighted-strip,
  aside,
  main {
    // grid elements have a default min-width of auto
    // which leads to unnecessary overflow in our layout
    min-width: 0;
  }

  .content {
    padding: 1.5rem 1.25rem;
  }

  aside,
  main {
    max-height: 100%;
    overflow: auto;
  }
}
