













































.parent-label {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: grid;
  grid-template-columns: 1fr auto;

  align-items: center;
  text-decoration: none;
  color: inherit;
}

a {
  display: block;
}

hr {
  border: transparent;
  border-top: 1px solid #d7d7d7;
  margin: 0;
}
