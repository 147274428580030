.layout .user-box {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 0.75rem;
  padding: 0 1.25rem;
  margin-bottom: 2rem;

  .image {
    background: lightblue;
    width: 32px;
    height: 32px;
    border-radius: 50rem;
  }

  p {
    margin-bottom: 0.25rem;
  }

  .username {
    font-size: 1.25rem;
    color: #3270AC;
    font-weight: 500;
    margin-bottom: 0;
  }

  .role {
    font-size: 0.75rem;
  }

  .info {
    display: flex;
    flex-direction: column;

    .icons {
      margin-top: 0.5rem;
      display: flex;

      a {
        color: #7A7B7D;
      }

      svg {
        margin-right: 1rem;
        cursor: pointer;
      }
    }
  }

  .logout {
    color: #7A7B7D;
    cursor: pointer;
  }
}
