// assets/css/global.scss

// customize some Bootstrap variables
// Override Bootstrap default variables here
// Do not edit any of the files in /vendor/bootstrap/scss/!

// Color Variables
// Bootstrap Color Overrides

$white: #fff !default;
$gray-100: #f8f9fc !default;
$gray-200: #ececec !default;
$gray-300: #dddfeb !default;
$gray-400: #d1d3e2 !default;
$gray-500: #b7b9cc !default;
$gray-600: #858796 !default;
$gray-700: #6e707e !default;
$gray-800: #5a5c69 !default;
$gray-900: #4e4e4e !default;
$black: #000 !default;

$blue: #0A69B0 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #aa1817 !default;
$orange: #fd7e14 !default;
$yellow: #f6c23e !default;
$green: #417505 !default;

$lime: #afc509 !default;
$teal: #20c9a6 !default;
$cyan: #36b9cc !default;


$light-blue: #5ec8f0 !default;
$light-red: #FAE8E8;
$light-green: #F0F5D6;
$light-lime: rgba(175,197,9,0.4) !default;
$light-cyan: rgba(94,200,240,0.4) !default;
$light-orange: rgba(lighten($orange, 20%),0.4) !default;

$dark-blue: #2ea9dd !default;
$gray-blue: #F0F2F5 !default;

$primary:       $blue !default;
$secondary:     $lime !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$light-primary:    $light-blue !default;
$light-secondary:  $light-lime !default;
$light-success:    $light-green !default;
$light-info:       $light-cyan !default;
$light-warning:    $light-orange !default;
$light-danger:     $light-red !default;

$enable-gradients: true;

$theme-colors: (
        "blue":       $blue,
        "indigo":     $indigo,
        "purple":     $purple,
        "pink":       $pink,
        "red":        $red,
        "orange":     $orange,
        "yellow":     $yellow,
        "green":      $green,
        "teal":       $teal,
        "cyan":       $cyan,
        "white":      $white,
        "gray":       $gray-500,
        "gray-dark":  $gray-700,
        "gray-blue":  $gray-blue,
        "dark-blue":  $dark-blue,
        "light-red":  $light-red,
        "light-blue": $light-blue,
        "light-green":$light-green,

        "light-primary":    $light-primary ,
        "light-secondary":  $light-secondary,
        "light-success":    $light-success,
        "light-info":       $light-info,
        "light-warning":    $light-warning,
        "light-danger":     $light-danger,
) !default;

// the ~ allows you to reference things in node_modules
@import "./base";
