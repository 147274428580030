//
// Main navbar
//

.certista-navbar-height {
  min-height: 3.4375rem;
  max-height: 3.4375rem;

  @include media-breakpoint-down(md) {
    min-height: 3.1875rem;
    max-height: 3.1875rem;
  }
}

.certista-navbar {
  min-height: 2.5rem;
  background-color: $primary;

  @include media-breakpoint-down(md) {
    padding-right: .5rem;
    padding-left: .5rem;

    .navbar-nav-scroll {
      max-width: 100%;
      height: 2.5rem;
      margin-top: .25rem;
      overflow: hidden;

      .navbar-nav {
        padding-bottom: 2rem;
        overflow-x: auto;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
      }
    }
  }

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      position: sticky;
      top: 0;
      z-index: 1071; // over everything in bootstrap
    }
  }

  .navbar-nav {
    .nav-link {
      padding-right: .5rem;
      padding-left: .5rem;
      color: $indigo;

      &.active,
      &:hover {
        color: $white;
        background-color: transparent;
      }

      &.active {
        font-weight: 600;
      }
    }
  }

  .navbar-nav-svg {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    vertical-align: text-top;
  }

  .dropdown-menu {
    @include font-size(.875rem);
  }

  .dropdown-item.active {
    font-weight: 600;
    color: $gray-900;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: .4rem .6rem;
    background-size: .75rem .75rem;
  }
}

/* start subnavbar */
.certista-subnavbar {
  top: 3.4375rem;
  background: $blue;
  padding: 0;
  font-size: 0.75rem;
  z-index: 100;
  max-height: 2.5rem;

  @include media-breakpoint-down(md) {
    top: 3.1875rem;
  }

  //@include media-breakpoint-only(lg){
  //  font-size: 0.8rem;
  //  max-height: 3.875rem;
  //}

  .fa,.fas {
    font-size: 0.875rem;
    margin-right: 0.25rem;

    @include media-breakpoint-down(lg) {
      font-size: 0.75rem;
      margin-right: 0.15rem;
    }
  }

  .navbar-nav {
    background: $blue;

    .nav-link {
      font-weight: bold;
      color: $white;
      text-align: center;
      border-bottom: 0.25rem solid transparent;
      padding: 0.75rem 0.75rem 0.5rem 0.75rem;

      @include media-breakpoint-down(lg) {
        padding: 0.5rem 0.25rem;
      }


      @include hover-focus {
        color: $white !important;
        border-bottom: 0.25rem solid;
      }

      &.disabled {
        color: $navbar-light-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      background-color: $light-blue !important;
      border-bottom: 0.25rem solid $white;
    }
  }

  .navbar-toggler {
    color: $white;
    border-color: transparent;
    padding: 0.5rem;

    @include hover-focus {
      outline: 1px none;
    }
  }

  .navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-bg;
  }

  .navbar-collapse {
    background: $blue;
  }

  .navbar-text {
    color: $black;
    font-weight: 600;

    a {
      color: $black;

      @include hover-focus {
        color: $white;
      }
    }
  }
}
/* end subnavbar */

/* start subsubnavbar */
.certista-subsubnavbar {
  top: 3.4375rem;
  background: $white;
  color: $blue;
  padding: 0;
  font-size: 0.75rem;
  z-index: 99;
  max-height: 2.5rem;

  @include media-breakpoint-down(md) {
    top: 3.1875rem;
  }

  //@include media-breakpoint-only(lg){
  //  font-size: 0.8rem;
  //  max-height: 3.875rem;
  //}

  .fa {
    font-size: 0.875rem;
    margin-right: 0.25rem;

    @include media-breakpoint-down(lg) {
      font-size: 0.75rem;
      margin-right: 0.15rem;
    }
  }

  .navbar-nav {
    background: $white;

    .nav-link {
      font-weight: bold;
      color: $blue;
      text-align: center;
      border-bottom: 0.25rem solid transparent;
      padding: 0.75rem 0.75rem 0.5rem 0.75rem;

      @include media-breakpoint-down(lg) {
        padding: 0.5rem 0.25rem;
      }
      @include hover-focus {
        color: $blue !important;
        border-bottom: 0.25rem solid;
      }

      &.disabled {
        color: $navbar-light-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      background-color: $white !important;
      border-bottom: 0.25rem solid $blue;
    }
  }

  .navbar-toggler {
    color: $blue;
    border-color: transparent;
    padding: 0.5rem;

    @include hover-focus {
      outline: 1px none;
    }
  }

  .navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-bg;
  }

  .navbar-collapse {
    background: $white;
  }

  .navbar-text {
    color: $black;
    font-weight: 600;

    a {
      color: $black;

      @include hover-focus {
        color: $white;
      }
    }
  }
}
/* end subsubnavbar */

// modal-navbar

.certista-modal-navbar {

  .navbar-nav {

    .nav-link {
      color: $gray-900;
      text-align: center;
      border-bottom: 0.125rem solid transparent;
      padding: 0.75rem 0.75rem 0.5rem 0.75rem;


      @include hover-focus {
        color: $blue !important;
        border-bottom: 0.125rem solid;
      }

      &.disabled {
        color: $navbar-light-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $blue;
      border-bottom: 0.125rem solid $blue;
    }
  }
}
