

















































button {
  display: block;
  border: none;
  text-align: left;

  svg {
    fill: currentColor;
  }
}
