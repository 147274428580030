// egde mit cr==1 ist zum Löschen markiert
.approval_cr_1 {
    text-decoration: line-through;
}
// egde mit cr==2 ist zum Hinzufügen markiert
.approval_cr_2 {
    text-decoration: underline;
    font-weight: normal;
}
/* End global ApprovalSystem */

.scrollable-y {
    max-height: 20rem;
    overflow-x: hidden;
    overflow-y: auto;
}

/* Mindestzeilenhöhe für Grid-Tabellen, die keine (Action)Buttons haben, sehen sonst so gestaucht aus */
.row.minheight, .form-row.minheight {
    min-height:2.5em!important;
}

/* Disabled-Status der Buttons deutlicher anzeigen als mit dem Bootstrap-Standard */
.btn.disabled, .btn:disabled {
    opacity: 0.30!important;
    border-style: dashed;
}

$custom-file-text: (
en: "Browse...",
de: "Durchsuchen...",
es: "Navegar..."
) !default;

$breadcrumb-divider: quote(">");

// $font-size-base:              1rem !default; // Assumes the browser default, typically `16px`

$h1-font-size:                1.2rem !default;
$h2-font-size:                1.1rem !default;
$h3-font-size:                1.0rem !default;
$h4-font-size:                0.9rem !default;
$h5-font-size:                0.85rem !default;
$h6-font-size:                0.80rem !default;

// Import Custom SB Admin 2 Variables (Overrides Default Bootstrap Variables)
@import "sb-admin/_common_variables.scss";

$grid-columns: 12;

// Import Bootstrap
@import "~bootstrap/scss/bootstrap";

@import "~jquery-ui/themes/base/all.css";

// Import Custom SB Admin 2 Mixins and Components
@import "sb-admin/mixins.scss";
@import "sb-admin/global.scss";
@import "sb-admin/utilities.scss";

// Custom Components
@import "sb-admin/forms.scss";
@import "sb-admin/dropdowns.scss";
@import "sb-admin/navs.scss";
@import "sb-admin/buttons.scss";
@import "sb-admin/cards.scss";
@import "sb-admin/badge";
@import "sb-admin/charts.scss";
@import "sb-admin/login.scss";
@import "sb-admin/error.scss";
@import "sb-admin/footer.scss";
@import "Blocks/BlockSystemRespOwnIcons.scss";
@import "Blocks/BlockSystemMegaMenu.scss";

h1, h2,
.h1, .h2 {
    font-weight: 700!important;
}

h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
    font-weight: 600!important;
}

small {
    font-size: 90%!important;
}

div.row.hover:hover,div.form-row.hover:hover {
    color: $table-hover-color;
    background-color: $table-hover-bg;
}

.tooltip .tooltip-inner {
    text-align: left;
    font-size:125%!important;
    color: black!important;
    background-color: var(--gray-blue);
    max-width: 100%!important;
    max-height: 33%!important;
    overflow-y: auto;
}

@import "layout/base.scss";
