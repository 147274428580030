.layout .nodeImage {
  padding: 0.75rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  background: white;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

  svg {
    height: 3rem
  }
}
