.layout .logged-in-users {
  h3 {
    padding: 0.5rem 1.25rem;
  }

  .logged-in-user {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.25rem;

    .username {
      flex: 1;
      padding: 0 0.5rem;
    }
  }
}
