label{
  font-weight: 600;
}

label.form-check-label{
  font-weight: 400;
}

// Switch active style
input[type=checkbox]:checked:not(:disabled) ~ .lever:active::before,
input[type=checkbox]:checked:not(:disabled).tabbed:focus ~ .lever::before {
  transform: scale(2.4);
  background-color: transparentize($switch-bg-color, .85);
}

input[type=checkbox]:not(:disabled) ~ .lever:active:before,
input[type=checkbox]:not(:disabled).tabbed:focus ~ .lever::before {
  transform: scale(2.4);
  background-color: rgba(0,0,0,.08);
}

// Disabled Styles
.switch input[type=checkbox][disabled] + .lever {
  cursor: default;
  background-color: rgba(0,0,0,.12);
}

.switch label input[type=checkbox][disabled] + .lever:after,
.switch label input[type=checkbox][disabled]:checked + .lever:after {
  background-color: $secondary;
}

// Input
.form-control {
  color: $gray-800;
  border-bottom-width: 1px;

  @include hover-focus {
    border-color: $primary;
  }
}

input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}