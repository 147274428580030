.mega-menu-trigger {
  border: none;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 2rem;
  padding: 0.35rem 1.25rem;
  color: white;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
