.layout aside {
  padding-bottom: 2rem;

  nav {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    a {
      display: grid;
      grid-template-columns: 24px 1fr;
      grid-gap: 1.25rem;
      align-items: center;
      padding: 0.5rem 1.25rem;
      border-left: 4px solid transparent;
      color: #7A7B7D;

      &.primary {
        border-radius: 0 50rem 50rem 0;
      }

      &.primary,
      &.secondary {
        grid-gap: 0.5rem;
      }

      &:hover {
        text-decoration: none;
        .text {
          text-decoration: underline;
        }
      }

      .text {
        grid-column: 2 / 3;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  @media (max-width: 992px) {
    box-shadow: 17px 0px 19px 0px rgba(0, 0, 0, 0.2);

    &.show {
      display: block;
      position: fixed;
      background: #f0f2f5;
      z-index: 9;
      width: 90%;
      max-width: 25rem;
      padding: 2rem 0;
      height: 100%;
    }
  }
}
