.user-images {
  display: flex;
  align-items: center;
}

.user-image {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 0.25rem;

  a img {
    border: 2px solid white;
    height: 2rem;
    width: 2rem;
    border-radius: 50rem;
  }

  small {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    padding: 0 0.5rem;
    font-size: 0.4rem;
  }
}
