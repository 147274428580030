// Custom Colors
$brand-google: #ea4335;
$brand-facebook: #3b5998;

// Set Contrast Threshold
$yiq-contrasted-threshold: 195 !default;

// Typography

$font-size-base:    0.875rem !default; // Assumes the browser default, typically `16px`

$body-color: $gray-900 !default;

$switch-bg-color: $primary;

$font-family-sans-serif: Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;

@mixin font-weight($weight) {
    $weights: (
            medium: 500
    );

    $output: $weight;
    @if map-has-key($weights, $weight) {
        $output: map-get($weights, $weight);
    }

    font-weight: $output;
}

// $font-weight-base: 400;
$headings-font-weight: 400 !default;

// Shadows
$box-shadow-sm: 0 0.125rem 0.25rem 0 rgba($gray-900, .2) !default;
$box-shadow: 0 0.15rem 1.75rem 0 rgba($gray-900, .15) !default;
// $box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;

// Borders Radius
$border-radius: 0 !default;
$border-color: darken($gray-200, 2%);

// Borders Buttons
$btn-border-radius-sm:        0;
$btn-border-radius-lg:        0;

// Input
$input-btn-focus-width:       .05rem;
$input-bg:                    $gray-200;
$input-disabled-bg:           $gray-600;
$input-border-color:          $gray-900;
$input-border-width:          0px;

// Spacing Variables
// Change below variable if the height of the navbar changes
$topbar-base-height: 4.375rem;
// Change below variable to change the width of the sidenav
$sidebar-base-width: 14rem;
// Change below variable to change the width of the sidenav when collapsed
$sidebar-collapsed-width: 6.5rem;

// Card
$card-cap-bg: $gray-100;
$card-border-color: $border-color;
$card-border-radius: .25rem;

// Adjust column spacing for symmetry
$spacer: 1rem;
$grid-gutter-width: $spacer * 1.5;

// Transitions
$transition-collapse: height .15s ease !default;


$toast-border-radius : 0;
$toast-max-width: 100%;
$toast-box-shadow: none;

// Dropdowns
$dropdown-font-size: 0.85rem;
$dropdown-border-color: $border-color;


// Sizes
// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
    (
            5: 5%,
            10: 10%,
            15: 15%,
            20: 20%,
            25: 25%,
            50: 50%,
            75: 75%,
            100: 100%,
            auto: auto
    ),
    $sizes
);


// Stripped row

$table-accent-bg:        #F7F7F7 !default;
