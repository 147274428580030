














































































section {
  border-left: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lists {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.static-links {
  margin-top: 2rem;
  border-top: 1px solid #d7d7d7;
}

.self-title {
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

li {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  grid-gap: 0.5rem;

  span {
    min-width: 0;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  svg {
    display: none;
  }

  &.active svg,
  &:hover svg {
    display: block;
  }
}
