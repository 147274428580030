


















































































































































.mega-menu {
  .modal-backdrop {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999999;
    background: rgba(0,0,0,.08);
    width: 100vw;
    height: 100vh;
  }

  .menu {
    position: fixed;
    left: 50%;
    top: 2rem;
    transform: translate(-50%, 0);
    width: 90%;
    max-width: 960px;
    background: white;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-template-rows: 1fr;
    line-height: 2;
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    overflow-x: auto;

    box-shadow: 0 15px 30px 0 rgba(0,0,0,.11), 0 5px 15px 0 rgba(0,0,0,.08);

    max-height: calc(100vh - 4rem);

    section {
      overflow-y: auto;
      min-width: 0;
      padding: 0.5rem 0;

      .title {
        padding: 0.25rem 1rem;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;

      li, a {
        padding: 0.25rem 1rem;
        color: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;

        &:hover {
          background: #e9edf5;
        }
      }
    }
  }
}
