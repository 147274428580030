.layout {
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-template-rows: auto auto 1fr;

  grid-template-areas:
    "nodeImage  header"
    "sidebar    main";
  .nodeImage {
    grid-area: nodeImage;
  }
  .header {
    grid-area: header;
  }
  aside {
    grid-area: sidebar;
  }
  main {
    grid-area: main;
  }
  .mobile-menu {
    display: none;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "header"
      "main"
      "mobileMenu";

    .nodeImage,
    aside {
      display: none;
    }

    .mobile-menu {
      display: block;
    }
  }
}
