.layout {
  aside,
  main,
  header .dropdown-list {
    &::-webkit-scrollbar {
      width: 0.35rem;
      height: 0.35rem;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.5rem;
      background: rgba(140, 140, 140, 0.5);
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.05);
    }
  }
}
