// Custom Card Styling

.card {
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.05);
  .card-header {
    padding: 1rem;
    background-color: white;
    .dropdown {
      line-height: 1;
      .dropdown-menu {
        line-height: 1.5;
      }
    }

    h3, h2{
      padding: 0;
      margin: 0;
    }
  }
  // Collapsable Card Styling
  .card-header[data-toggle="collapse"] {
    text-decoration: none;
    position: relative;

    &::after {
      position: absolute;
      right: 0;
      top: 0;
      padding-right: 1.725rem;
      line-height: 51px;
      font-weight: 900;
      content: '\f107';
      font-family: 'Font Awesome 5 Free';
      color: $gray-400;
    }
    &.collapsed {
      border-radius: $card-border-radius;
      &::after {
        content: '\f105';
      }
    }

  }
}
