body {
  height:100vh;
}
/*
h2 {
  font-size: 1.125rem;
  font-weight: 700;
}

h3 {
  font-size: 1rem;
  font-weight: 500;
}

h4 {
  font-size: 0.87rem;
  font-weight: 500;
}

h5 {
  font-size: 0.75rem;
  line-height: 0.875rem;
}
*/
.font-weight-medium {
  @include font-weight(medium);
}

// subnavbar

.badge-pill {
  font-family: $font-family-sans-serif;
}

// font-awesome

.fa-certista {
  font-size: 1.625rem;
}


#profile_card {
  margin-top:-100px;

  @include media-breakpoint-down(md) {
    background:linear-gradient(rgba(255,0,0,0) 6rem, white 6rem);
    margin-top:-6rem;

    img{
        width: 10rem;
    }
  }
}

.overlay {
  top: 0;
  left: 0;
  transition: 2.0s;
  z-index: 99999;
}

.overlay-content {
  font-size:200%;
  border-radius: 10px;
  z-index:100000;
}

//
//#BlockTodoList_overlay {
//  top: 0;
//  left: 0;
//  transition: 2.0s;
//  z-index: 99999;
//}
//
//.overlay-content-edit {
//  width:50%;
//  max-width:80%;
//  height:90%;
//  max-height: 90%;
//  z-index:100000;
//  overflow:auto;
//}

$levels: (1,2,3,4,5);


@mixin text-or-box-shadow ($level, $text_or_box) {

  @if $level == 1 {
    #{$text_or_box}-shadow: 0 2px 3px 0px rgba(0,0,0,0.05);
  } @else if $level == 2 {
    #{$text_or_box}-shadow: 0 2px 3px 0px rgba(0,0,0,0.2);
  } @else if $level == 3 {
    #{$text_or_box}-shadow: 0 2px 4px 0px rgba(0,0,0,0.2);
  } @else if $level == 4 {
    #{$text_or_box}-shadow: 0 2px 5px 0px rgba(0,0,0,0.25)
  } @else if $level == 5 {
    #{$text_or_box}-shadow: 0 4px 4px 0px rgba(0,0,0,0.30);
  }

}

@each $level in $levels{
  .text-shadow-#{$level}{
    @include text-or-box-shadow ($level, 'text');
  }
  .box-shadow-#{$level}{
    @include text-or-box-shadow ($level, 'box');
  }
}

/* accordion closed */
.nav-item .nav-link[aria-expanded="false"] .accordion-icon::after {
  font-family: "Font Awesome 5 Free";
  display: inline;
  font-size: 1rem;
  font-weight: 900;
  content: " \f078";
}

/* accordion opened */
.nav-item .nav-link[aria-expanded="true"] .accordion-icon::after {
  font-family: "Font Awesome 5 Free";
  display: inline;
  font-size: 1rem;
  font-weight: 900;
  content: " \f077";
}

.scrollable {
  max-height: 20rem;
  overflow-y: auto;
}
.w-0 {
  flex: 0 0 0 !important;
}

.w-chain {
  width: 4.375rem !important;
}

.shrink {
  padding: 0;
  flex: 0 0 0 !important;
  transition: all 200ms;
}

.expand {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  transition: all 200ms;
}

.dhx_cal_navline div.dhx_minical_icon {
  left: 480px !important;
}

.dhx_cal_header .dhx_scale_bar a {
  color: #0288d1;
}

.dhx_cal_container div {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.dhtmlx-event-ro {
	opacity: 0.3;
}

#content {
  padding-top: 3.4375rem;

  @include media-breakpoint-down(md) {
    padding-top: 3.1875rem;
  }
}

#div_sidebar:empty {
	display: none;
}

.ribbon {
  position: absolute;
  right: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 100px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#F79E05 0%, #F79E05 100%);
  position: absolute;
  top: 19px; right: -21px;
}
.ribbon span::before {
  content: "";
  position: absolute; left: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid #F79E05;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #F79E05;
}
.ribbon span::after {
  content: "";
  position: absolute; right: 0px; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #F79E05;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #F79E05;
}

.form-control:disabled,
.form-control[readonly] {
	background-color: lightgray;
	border: 2px solid pink;
	opacity: 1;
}