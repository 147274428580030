.mobile-menu {
  background: inherit;
  grid-area: mobileMenu;
  padding: 0.625rem;
  box-shadow: 0 -10px 15px -3px rgba(0, 0, 0, 0.2);
  z-index: 1;

  .icons {
    display: grid;
    grid-template-columns: 1fr repeat(4, auto) ;
    grid-gap: 1.5rem;
    padding: 0.75rem 1.25rem;
    a {
      color: inherit;
    }

    svg {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}
