.layout > header {
  background: white;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-template-areas:
    "nodeTitle responsible actions";
  padding: 0.75rem 1.25rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  z-index: 1;
  position: relative;

  &:after {
    position: absolute;
    z-index: 2;
    content: '';
    left: -3px;
    top: 0;
    background: white;
    width: 6px;
    height: 100%;
  }

  .node-title,
  .actions,
  .responsible-icons {
    display: flex;
    align-items: center;
  }

  .node-title {
    grid-area: nodeTitle;
  }

  .actions {
    grid-area: actions;
    display: flex;
  }

  .responsible-icons {
    grid-area: responsible;

    padding-right: 2rem;
  }
}
