.row.collapse {
  margin-left: -20%;
  left: 0;
  transition: margin-left .15s linear;
}

.row.collapse.show {
  margin-left: 0 !important;
}

.row.collapsing {
  margin-left: -20%;
  left: -0.05%;
  transition: all .15s linear;
}

.bd-sidebar {
  top: 3.4375rem;
  z-index: 0;
}

@include media-breakpoint-down(lg) {
  .row.collapse {
    margin-left: -33.27%;
    left: 0;
    transition: margin-left .15s linear;
  }

  .row.collapse.show {
    margin-left: 0 !important;
  }

  .row.collapsing {
    margin-left: -33.27%;
    left: -0.05%;
    transition: all .15s linear;
  }
}

@include media-breakpoint-down(md) {
  .row.collapse,
  .row.collapsing {
    margin-left: 0 !important;
    left: 0 !important;
    overflow: visible;
  }

  .row > .collapse-sidebar.collapse {
    display: flex !important;
    margin-left: -50% !important;
    transition: all .3s linear;
    position: fixed;
    z-index: 1050;
    max-width: 0;
    min-width: 0;
    flex-basis: auto;
  }

  .row > .collapse-sidebar.collapse.show {
    margin-left: 0 !important;
    width: 50%;
    max-width: 50%;
    align-items: flex-start;
    min-width: initial;
  }
  .row > .collapse-sidebar.collapsing {
    display: flex !important;
    margin-left: -50% !important;
    transition: all .3s linear !important;
    position: fixed;
    z-index: 1050;
    min-width: initial;
  }
}

@include media-breakpoint-between(xs, sm) {

    .row.collapse,
    .row.collapsing {
      overflow: visible;
    }

    .row > .collapse-sidebar.collapse {
      display: flex !important;
      margin-left: -100% !important;
      transition: all .3s linear;
      position: fixed;
      z-index: 1050;
      max-width: 0;
      min-width: 0;
    }

    .row > .collapse-sidebar.collapse.show {
      margin-left: 0 !important;
      width: 100%;
      max-width: 100%;
      overflow: visible;
    }
    .row > .collapse-sidebar.collapsing {
      transition: all .3s linear !important;
      z-index: 1050;
      margin-left: -100% !important;
    }
}

#toggler {
  height: 100%;
  position: absolute;
  right: -9px;
  width: 10px;
  z-index: 10;
}

#toggler::before {
  content: "";
  height: 100%;
  left: -1px;
  position: absolute;
  width: 2px;
  background: $blue;
  //transition: background-color 300ms ease-in-out 200ms;
}

//#toggler:hover::before {
//  background: $blue;
//}

#toggler button {
  position: sticky;
  top: calc(40% - 18px);
  height: 36px;
  width: 10px;
  left: -1px;
  color: $white;
  background: $blue;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 0 2px 2px 0;
  //transition: all 300ms ease-in-out 200ms;
}

#toggler button:focus {
  outline: none;
}

//#toggler:hover button {
//  color: $white;
//  background: $blue;
//}

#toggler button[aria-expanded="false"] div::after {
  font-family: "Font Awesome 5 Free";
  display: inline;
  font-size: 0.75rem;
  font-weight: 900;
  content: " \f0d9";
}

#toggler button[aria-expanded="true"] div::after {
  font-family: "Font Awesome 5 Free";
  display: inline;
  font-size: 0.75rem;
  font-weight: 900;
  content: " \f0da";
}