
























































































.c-dropdown {
  a {
    text-decoration: none;
  }

  &--trigger {
    border: none;
    display: flex;
    align-items: center;
  }

  &--icon {
    margin-left: 0.5rem;
  }

  &--list {
    list-style-type: none;
    background: white;
    z-index: 99999;
    padding: 0.5rem 0;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    max-width: 20rem;
    max-height: 70vh;
    overflow: auto;
    border: 1px solid rgba(0, 0, 0, 0.15);

    & .c-dropdown--list-item {
      display: block;
      padding: 0.25rem 1.5rem;
      font-size: 0.9rem;
      text-decoration: none;

      &:hover {
        cursor: pointer;
        color: #16181b;
        background-color: #f8f9fc;
      }
    }
  }

  &--trigger, &--list {
    // If these have margins, popper complains
    margin: 0;
  }
}
